import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private panel: MatSidenav;
  private vcf: ViewContainerRef;

  public constructor() {}

  public setPanel(sidenav: MatSidenav) {
    this.panel = sidenav;
  }

  public setContentVcf(viewContainerRef: ViewContainerRef) {
    this.vcf = viewContainerRef;
  }

  private createView(template: TemplateRef<any>) {
    this.vcf.clear();
    this.vcf.createEmbeddedView(template);
  }

  public open(template: TemplateRef<any>) {
    this.createView(template);
    return this.panel.open();
  }

  public close() {
    return this.panel.close();
  }

  public toggle() {
    return this.panel.toggle();
  }
}
